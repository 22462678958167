@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.App {
  text-align: center;
}

body {
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  text-decoration: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.icon-animation {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.icon-appear-1 {
  animation: fadeIn 0.5s ease-in-out 0.2s forwards;
}

.icon-appear-2 {
  animation: fadeIn 0.5s ease-in-out 0.4s forwards;
}

.icon-appear-3 {
  animation: fadeIn 0.5s ease-in-out 0.6s forwards;
}

.icon-appear-4 {
  animation: fadeIn 0.5s ease-in-out 0.8s forwards;
}

.icon-appear-5 {
  animation: fadeIn 0.5s ease-in-out 1s forwards;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  }
  
  .custom-dot-list-style .react-multi-carousel-dot button{
    border: none;
    width: '5px';
    height: '5px';
    padding: '10px';
    border-radius: 0;
    background: '#f1f1f1';
  }
  
  .react-multi-carousel-dot.react-multi-carousel-dot--active button{
    background-color: '#3A98B8 !important';
    border: none;
    width: '5px';
    height: '5px';
    border-radius: none;
  }

  /* Width of the entire scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff; /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bebebe; /* Color of the handle */
  border-radius: '10px';
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5; /* Color on hover */
}
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }